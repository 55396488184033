import React from "react"
import PropTypes from "prop-types"
import "../styles/main.scss"
import Header from "./header"
import Footer from "./footer"
import "./layout.scss"

const Layout = ({ children }) => (
  <div
    style={{
      display: `flex`,
      flexDirection: `column`,
      justifyContent: `space-between`,
      minHeight: `100vh`,
    }}
  >
    <Header logo={`dark`} text={`text-secondary`} />
    <div
      className="text-secondary"
      style={{
        margin: `0 auto`,
        maxWidth: `80vw`,
        padding: `0px 1.0875rem 1.45rem`,
        paddingTop: 0,
      }}
    >
      <main>{children}</main>
    </div>
    <Footer />
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
