import React from "react"
import styles from "./footer.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import {
  faFacebookF,
  faYoutube,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons"

const Footer = () => {
  return (
    <footer className={`${styles.footerUniversal} row text-secondary`}>
      <div className="col-sm-12 d-flex justify-content-center">
        <a
          className="text-secondary"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/PHOENIXSTUDIO2014/"
        >
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
        <a
          className="text-secondary"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.youtube.com/channel/UCcWZ5UiwLpMACymI4O2pHVg?view_as=subscriber"
        >
          <FontAwesomeIcon icon={faYoutube} />
        </a>
        <a
          className="text-secondary"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/phoenix-architecture-642191186/"
        >
          <FontAwesomeIcon icon={faLinkedinIn} />
        </a>
        <a
          className="text-secondary"
          target="_blank"
          rel="noopener noreferrer"
          href="mailto:services.phoenixarchitecture@gmail.com"
        >
          <FontAwesomeIcon icon={faEnvelope} />
        </a>
      </div>
      <p
        className={`col-sm-12 d-flex justify-content-center ${
          styles.copyright
        }`}
      >
        Copyright © {new Date().getFullYear()} - Phoenix Architecture. All
        rights reserved.
      </p>
    </footer>
  )
}

export default Footer
