import { Link } from "gatsby"
import React from "react"
import styles from "./menu.module.scss"

const Menu = ({ text }) => (
  <ul className={styles.list}>
    <li className={styles.dropdown}>
      work
      <ul className={styles.innerList}>
        <li>
          <Link to="/work/exteriors/" className={text}>
            exteriors
          </Link>
        </li>
        <li>
          <Link to="/work/interiors/" className={text}>
            interiors
          </Link>
        </li>
        <li>
          <Link to="/work/walkthroughs/" className={text}>
            walkthroughs
          </Link>
        </li>
        <li>
          <Link to="/work/360/" style={{ fontSize: `0.86em` }} className={text}>
            360
          </Link>
        </li>
      </ul>
    </li>
    <li>
      <Link to="/about/" className={text}>
        about
      </Link>
    </li>
    <li>
      <Link to="/contact/" className={text}>
        contact
      </Link>
    </li>
  </ul>
)

export default Menu
