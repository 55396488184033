import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Menu from "./menu"
import LogoLight from "./logoLight"
import Logo from "./logo"
import styles from "./header.module.scss"

const Header = ({ logo, text }) => (
  <header
    className={text}
    style={{
      marginBottom: `1.45rem`,
    }}
  >
    <div
      className="row"
      style={{
        margin: `0 auto`,
        maxWidth: `90vw`,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <div className={`col-sm-12 col-md-2 ${styles.logo}`}>
        <Link to="/">{logo === `light` ? <LogoLight /> : <Logo />}</Link>
      </div>
      <div className={`col-sm-12 col-md-10 d-flex ${styles.menu}`}>
        <Menu text={text} />
      </div>
    </div>
  </header>
)

Header.propTypes = {
  text: PropTypes.string,
}

Header.defaultProps = {
  text: ``,
}

export default Header
