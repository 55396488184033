import React from "react"

import Layout from "../../components/layoutGallery"
import SEO from "../../components/seo"
import styles from "./360.module.scss"

const Three60Page = () => {
  return (
    <Layout>
      <SEO title="Work-360" keywords={[`work`, `360`, `samples`, `services`, `architecture`]} />
      <div className={styles.container}>
        <h4 className={`${styles.heading} text-secondary`}>work / 360</h4>
        <p className="text-center">Coming soon.</p>
      </div>
    </Layout>
  )
}

export default Three60Page
